
.willCompare .containerColumns
{
 padding: 15px 8px 15px 8px;
 display: block;
}
.willCompare .containerColumns > div{
  max-width: 100%
}


.willCompare .columnContent
{
 padding: 26px 20px 30px 20px;
 width: 100%;
}
.willCompare .columnContent h2{
  color:#1F8299;
  font-size:1.2rem;
  font-weight: 600;
  margin:0;
}


.willCompare .columnContent nav.will_pages ul{
  list-style-type: none;
  display: flex;
  padding-left: 0px;
  margin-top:20px;
  margin-bottom: 30px;
}
.willCompare .columnContent nav.will_pages li{
  margin-right: 10px

}
.willCompare .columnContent .MuiSvgIcon-colorPrimary,
.willCompare .columnContent .transcription .choice {
    color: #1F8299;
}
.willCompare .columnContent .transcription .choice .corr, 
.willCompare .columnContent .transcription .choice .expan {
    background-color: #2E5D81;
    border-radius: 4px;
    padding: 5px 5px 5px 5px;
    top: 110%;
    left: 0%;
}

.willCompare .columnContent  .edition {
  line-height: 1.5rem;
}
