.menuCMS {
  display: flex;
}

.menuCMS .link {
  text-transform: none;
  color: #212121;
  font-size: 1rem;
  font-weight: 500;
}

.menuCMS .link:hover:active {
  color: #0091ea;
  font-weight: 600;
  background-color: #eceff1;
}

.menuCMS .activedLink {
  color: #0091ea;
  font-weight: 600;
}
