.explore iframe {
  align-items: center;
  width: 100%;
  height: 60em;
  border:none;
  box-shadow: none;
  height: 4100px;
}
.explore .paddingContainer{
	padding: 0;
}
