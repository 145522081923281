.lostPassWord .form {
  max-width: 600px;
  margin-top: 2rem;
}

.lostPassWord .input {
  margin-bottom: 25px;
  margin-top: 15px;
  font-size: 0.9rem;
}

.lostPassWord .submit {
  margin-top: 15px;
}

.lostPassWord label span{
	background-color: white;
}
.lostPassWord #btClose{
	margin-bottom: 30px;
}