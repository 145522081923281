.content{
  font-family: "Fira Sans";
  color: black;
}
.content.blog .listContainer > div:first-of-type{
  display: flex;
  justify-content: flex-end;
}
.content.blog .countNews{
  margin-bottom: 20px;
  font-size: 1rem;
}
.content.blog .list_news{
  padding: 20px 20px 20px 20px;
}
.content.blog .list_news .item{
  margin-bottom: 50px;
}
.content.item .bg-white{
  padding: 30px 20px 30px 20px;
}
.content .bg-gray{
  padding: 6px 15px 6px 15px;
}
.content.blog .content h2{
  font-size: 1.275rem;
  margin-bottom: 8px;
}


 /* Liste des actualités dans la colonne de gauche */
.content .leftMenu{
  padding: 10px 20px 20px 20px;
}
.content .leftMenu ul{
  margin:0;
  padding: 0;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.content .leftMenu li{
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid white;
  white-space: normal;
}
.content .leftMenu li:hover{
  background-color: inherit;
}
.content .leftMenu li a{
 font-weight: 600;
 font-size: 1.1rem;
 line-height: 1.6rem;
 color: #1F8299
}
.content .leftMenu li a:hover,
.content .leftMenu li a.active{
 color:#B91918;
}


.content .authors{
  font-size: 1rem;
}
.content  .date{
  font-size: 0.9rem;
}

.content.item h1{
  margin-top: 0px;
}
.content.item .bg-gray{
  margin-bottom: 30px;
}
