.resultWills .chip {
  margin: 0.5em;
}
.list-container #chipRoot {
  margin-top: 10px;
  margin-bottom: 40px;
}
.list-container #chipRoot .MuiChip-root{
  border-radius: 0px;
  background-color: #F0F0F0;
  font-family: "Fira Sans";
  color:#1F8299;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 10px;
}
.list-container #chipRoot .MuiChip-deleteIcon {
  color:#8DB2C2;
}
.list-container #chipRoot .MuiChip-deleteIcon:hover {
  color:#B91918;
}

.list-container .menuComparaison #btCompare{
  margin-top: 20px;
}

.resultWill{
  color:#2E5D81;
}
.resultWill a:hover{
   color: #8DB2C2;
}
.resultWill .will_date {
  font-size: 0.995rem;
  line-height: 1.3;  
  font-family: 'Fira Sans';
  color: black;
}
.resultWill a{
  color:#1F8299;
  font-family: "Fira Sans";
  font-weight: 600;
  font-size:1.1rem;
}
.resultWill > div {
  padding: 0;
}
.resultWill > div > div {
  border-bottom: 1px solid #C6C6C5;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 6px;
}
.resultWill:last-of-type > div > div{
  border-bottom: none;
}

.resultWill i.remove{
  color:#B91918!important;
}
.resultWill p {
  margin-top: 5px;
  font-family: "Fira Sans", sans serif;
  color:black;
}
.resultWill p a{
  margin-right: 8px;
}
.resultWill p mark{
  background-color: #C7D7E1;
  padding-left: 3px;
  padding-right: 3px;
  display: inline-block;
}

.resultWill p .MuiDivider-inset{
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 0px;
}


.resultWill .fa-level-down-alt,
.resultWill br[class^="lb"]{
  display: none;
}

.info .message {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.info .message .iconVariant {
  margin-right: 5px;
}
