footer{
    background-color: white;
    box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
    margin-top: 70px;
    padding-top: 20px;
}
footer .footerContainer {
  max-width: 1280px;
  margin:auto; 
  color:#B91918;
  align-items: center;
}
footer .footerContainer a{
  color:#1F8299;
  font-size:0.98rem;
  text-align: center;
}
footer .footerContainer a:hover{
  color:#8094B1;

}
footer .footer_menu{
  align-items: center;
}
footer .footerContainer img{
  max-width: 80px;
  max-height: 70px;
}

footer .footerLogos > div > div{
  display: inline-block;
  max-width: 120px;
}
footer address {
  font-style: normal;
}
