html{
  font-size:15px;
}
body{
    font-family: 'Fira Sans', sans-serif;
}
.App {
  margin: 0 auto;
  display: block;
  background-color: #F5F5F5;
  min-height: 500px;
}
.mainContainer{
  max-width: 1280px;
  margin:auto; 
  min-height: 600px;
}

a{
  color:#1F8299;
  text-decoration: none;
}
a:hover{
  text-decoration: none!important;
  color: #8DB2C2;
}

.d-flex{
  display: flex;
}
.d-block{
  display: block;
}
.d-inline-block{
  display: inline-block;
}
.d-none{
  display: none;
}
.flex-end{
  justify-content: flex-end;
}
.p-relative{
  position: relative;
}
.p-absolute{
  position: absolute;
}

.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}

.align-items-start{
  align-items: flex-start;
}
.align-items-center{
  align-items: flex-center;
}
.align-items-end{
  align-items: flex-end;
}

.text-align-left{
  text-align: left;
}
.text-align-right{
  text-align: right;
}
.text-align-center{
  text-align: center;
}



.bg-xlight-gray{
  background-color: #F5F5F5;
}
.bg-light-gray{
  background-color: #F0F0F0;
}
.bg-gray{
  background-color: #DADAD9;
}
.bg-mid-gray{
  background-color: #C6C6C5;
}
.bg-dark-gray{
  background-color: #9D9C9C;
}
.bg-xdark-gray{
  background-color: #6F6F6E;
}
.bg-black{
  background-color: black;
}
.bg-white{
  background-color: white;
}
.bg-primaryMain{
  background-color: #2E5D81;
}
.bg-primaryLight{
  background-color: #8094B1;
}
.bg-secondaryMain{
  background-color: #1F8299;
}
.bg-secondaryLight{
  background-color: #8DB2C2;
}
.bg-error {
   background-color:  '#B91918';
}
.bg-warning {
  background-color: '#EC6607';
}
.bg-danger {
  background-color: '#BE1622';
}
.bg-info {
   background-color: '#008ECF';
}
.bg-success {
   background-color: '#009640';
}

.text-black{
  color: black;
}
.text-white{
  color: white;
}
.text-primaryMain{
 color: #2E5D81;
}
.text-primaryLight{
  color: #8094B1;
}
.text-secondaryMain{
  color: #1F8299;
}
.text-secondaryLight{
  color: #8DB2C2;
}
.text-error {
  color: #B91918;
}
.text-warning {
  color: #EC6607;
}
.text-danger {
  color: #BE1622;
}
.text-info {
   color: #008ECF;
}
.text-success {
   color: #009640;
}

.md-spacing-3{
 width: calc(100% + 24px);
 margin: -12px;
}


#explorer .text-green{
  color: #C4D983;
}
#explorer .bg-green{
  color: #009640;
}
#explorer .text-blue{
  color: #008ECF;
}
#explorer .bg-blue{
  color: #A1D9F7;
}
#explorer .text-camel{
  color: #EC6607;
}
#explorer .bg-camel{
  color: #E9C48D;
}
#explorer .text-pink{
  color: #BE1622;
}
#explorer .bg-pink{
  color: #EDAEAD;
}
#explorer .text-purple{
  color: #945FA4;
}
#explorer .bg-purple{
  color: #D18CDC;
}

.text-uppercase{
  text-transform: uppercase;
}
.smallcaps{
  font-variant: small-caps;
}
p.lead{
  font-weight: 500;
}
.fontWeightLight{
  font-weight:  400!important;
}
.fontWeightRegular{
  font-weight:  500!important;
}
.fontWeightMedium{
  font-weight:  600!important;
}
.fontWeightBold{
  font-weight:  700!important;
}

strong,b{
  font-weight: 600;
}

blockquote {
  font-size: 120%;
}
blockquote p{
  font-style:italic;
  color: #6F6F6E;
}
form{
  font-family: 'PT Sans';
}
form input,
form select{
  border-radius:5px;
  border:1px solid black;
}
form label{
  font-weight: bold;
}

/*spinner*/
.MuiCircularProgress-root{
  display: block;
  margin:auto;
  position: absolute;
  left:45%;
  top:30px;
}


nav.breadcrumbs {
 font-family: 'PT Sans'!important;
 font-size: 1.05rem!important;
 color:#6F6F6E!important;
 margin-left: 10px;
 margin-bottom: 30px;
}
nav.breadcrumbs i{
 color:#B91918;
}
nav.breadcrumbs a{
 color:#1F8299;
}
nav.breadcrumbs a:hover{
 color:#8DB2C2;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Fira Sans', sans-serif!important;
}
/* Titre article/actualité*/
.content h1{
  font-size: 1.866rem;
  font-weight: 600;
}
/* Titre bloc */
h1.heading{
  text-transform: uppercase;
  background-color: #2E5D81;
  margin-bottom: 10px;
}

.select button,
input{
  font-family: 'PT Sans', sans-serif!important;
  width: 100%;
  font-weight: 600;
  background-color: white;
}

input{
  color:#2E5D81;
}

.select button,
input, 
textarea{
border-radius: 5px;
border: 1px solid #9D9C9C!important;
}
.MuiOutlinedInput-root input.MuiOutlinedInput-input{
  border:none!important;
  color:#1F8299!important;
  height:20px!important;
  min-height: 20px!important;
  padding: 12.5px 14px!important;
}


.select button > div{
 color:#2E5D81;
  font-family: 'PT Sans', sans-serif;
  font-weight: 600;
}

.select button,
input:not([type="checkbox"]){
height: 32px!important;
min-height: 32px!important;
}


.select button span:before{
width: 0;
height: 0;
border-style: solid;
border-width: 10.4px 6px 0 6px;
border-color: #1f8399 transparent transparent transparent;
transform: none;
top:5px;
}

.iconButton{
  min-width: 10px!important;
}
.iconButton:hover,
.iconButton:focus,
.iconButton:active{
  background-color: transparent!important;
  transform: inherit;
  transition: inherit;
}
.iconButton i{
 color:#1F8299;
 font-size: 1.1rem;
}
.iconButton i.remove{
  color:#B91918!important;
}
.iconButton:hover i{
 color:#B91918;
}
.iconButton:focus i{
 color:#8DB2C2;
}

.iconButton:disabled i,
.iconButton.disabled i{
 color: #8DB2C2;
}
.button.iconButton.plain i{
  padding-right: 0px!important;
}


/* Checkbox */
.MuiCheckbox-root{
  padding-right: 5px;
  margin: 0;
}
/* Checkbox non cochée */
.MuiCheckbox-root path{
  color: #8CB2C2;
}
/* Checkbox cochée */
.MuiCheckbox-root.Mui-checked path
{
  color: #B91918!important;
}
.MuiCheckbox-root  svg {
    font-size: 1.1rem;
}

/* Formulaire de recherche sur la page d'accueil et la page Rechercher */
.searchForm{
    font-family: "PT Sans", sans-serif!important;
}
i.fa-times,
i.fa-question-circle{
 color:#1f8399;
 font-size:1rem;
}
i.fa-question-circle{
  width: 12px;
}
svg.cancel-icon{
   flex-basis: 25px!important;
  -webkit-flex-basis: 25px!important;
}
svg.cancel-icon path{
  stroke-width: 20px;
  stroke: #1f8399;
}

input::placeholder{
  color:#8DB2C2;
  font-weight: 400;
  font-size: 1rem;
}
.searchForm button {
 min-width: 5px;
}
.searchForm button:hover {
   background-color: inherit;
}

.searchForm .selected div,
.notices .selected div,
.searchForm .DayPickerInput input{
  color: #B91918!important;
  font-weight: bold;
}
.DayPickerInput input{
  color:black!important;
}

.searchForm button.select,
.searchForm input,
.searchBar input{
  background-color: white!important;
}
.searchForm button.select:hover,
.searchForm input:hover,
.searchBar input:hover,
.notices .headingBar .select button:hover{
  background-color: rgba(255, 255, 255, 0.8)!important;
}
.searchForm label {
  color:black;
}
.searchForm ul,
.notices .headingBar ul {
 top: 0px;
}
.searchForm ul input,
.notices .headingBar ul input{
  border: none;
  border-radius:0px;
}
/* la croix rouge à droite du champ pour supprimer le critère  */
.searchForm button.clear,
.searchForm svg.cancel-icon,
.notices .headingBar button.clear,
.searchForm .clear{
  padding-left: 12px!important;
  padding-right: 0px!important;
}
.searchForm i.fa-times:hover,
.searchForm svg.cancel-icon:hover,
.searchForm svg.cancel-icon:hover path{
  color:#B91918!important;
  fill:#B91918;
  stroke:#B91918;
}
.searchForm button.clear i,
.searchForm svg.cancel-icon,
.notices .headingBar button.clear i{
  display: block;
}



.home .searchResults {
  padding-top: 20px;
}
.searchResults .countResults{
  font-size:0.9rem;
  font-weight: 700;
  color:black;
}
.home .searchResults .countResults{
  margin-top: 20px;
  margin-bottom: 30px;
}

.tooltip {
  border: 1px solid #2E5D81;
  border-radius: 5px;
  font-size: 0.9rem;
  padding: 1em;
  max-width: 22em;
  background-color: white;

}


.card{
 padding: 6px 24px 20px 24px;
 margin-bottom: 20px;
 box-shadow: none;
 border-radius: 5px;
}

h2.card-title,
h1.heading{
  display: inline-block;
  color:white;
  padding: 4px 10px 4px 10px;
  font-weight: 400;
  font-size: 0.985rem;
}

h2.card-title i{
  padding-right: 3px;
}
.paddingContainer{
 padding: 10px 10px 10px 10px;
}
.paddingContainer > h1{
  margin-top: 0px!important;
}

img{
  max-width: 100%;
}

.MuiDialog-paper{
  margin:5;
  padding: 5;
}

.button.MuiButtonBase-root{
  padding: 4px 7px 4px 7px;
  text-transform: none!important;
  font-size: 0.933rem;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  border-radius: 4px!important;
  display: inline-block;
  font-family: 'PT Sans'!important;
}
.button.disabled,
button:disabled{
  cursor: not-allowed!important;
}
.button:hover{
  text-decoration: none;
}

.outlined.secondary-light,
.outlined.secondary-light:focus{
  border-color: #1F8299;
  color:#1F8299;
  border-style: solid;
  border-width: 1px;
  background-color: white;
}
.outlined.secondary-light:hover{
  border-color: #8DB2C2;
  background-color:#DADAD9;
}


.button.plain{
  min-height: 10px;
  padding: 4px 7px 4px 7px;
  color: white;
  font-size: 0.975rem;
  display: inline-block;
  font-family: 'PT Sans', sans-serif;
  min-width: 16px;
}
.button.plain.primaryMain{
  background-color:#1F8299;
}

.button.plain.primaryLight{
  background-color:#8DB2C2;
}
.button.plain.bg-danger{
  background-color:#B91918;
}
.button.plain.bg-secondaryLight{
  background-color: #8DB2C2;
}
.button.plain.bg-secondaryMain{
  background-color: #1F8299;
}

.button.plain i{
 color:white;
  padding-right: 5px;
}
.button.plain:hover{
  background-color: #B91918;
}
.button.plain.bg-danger:hover,
.button.plain.bg-danger:focus{
  background-color:#8DB2C2!important;
}

.button.plain:disabled,
.button.plain.disabled{
  background-color: #C7D7E1;
}


.MuiMenuItem-root{
  min-height: 20px;
}


.pagination a[rel=next]:before,
.pagination a[rel=prev]:after{
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  visibility: visible;
  font-size: 1.6rem;
  line-height: 1.2rem;
  vertical-align: bottom;
  color: #1F8299;
}
.pagination a[rel=next]:before{
  content: '\f0da';
}
.pagination a[rel=prev]:after{
  content: '\f0d9';
}
.pagination a[rel=prev],
.pagination a[rel=next]{
  visibility: hidden;
  display: inline-block;
  font-size: 0px!important;
  line-height: 0.7rem!important;
}
.pagination a:hover[rel=next]:before,
.pagination a:hover[rel=prev]:after{
  color: #B91918;
}

.pagination{
  margin-top: 20px;
}
.pagination a[disabled=""]{
  display: none;
}
.pagination a{
  background-color: #8DB2C2;
  color: white;
  padding: 1px 7px;
  min-height: 10px;
  color: white;
  font-size: 0.975rem;
  display: inline-block;
  font-family: 'PT Sans', sans-serif;
}
.pagination a:hover{
  background-color: #B91918;
}
.pagination a.active{
  background-color: #1F8299;
  box-shadow:none;
}
.pagination a.active:focus{
  box-shadow:none;
}
.notices .pagination a{
  padding: 1px 1px;
}


.map-container {
  width: 100%;
  height: 35em;
  position: relative;
  z-index: 1;
}

.map-popup {
  overflow-y: auto;
  max-height: 9em;
  font-size: 0.8rem;
}
.map-popup h4 {
  text-align: center;
}

.rightSidebar {
  margin-top: 1em;
}

.tag-container {
  margin-top: 440px;
}

/*Legend specific*/
.legend {
  padding: 1px 2px;
  font: 14px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  /*box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);*/
  /*border-radius: 5px;*/
  line-height: 24px;
  color: #555;
}
.legend h5 {
  text-align: center;
  font-size: 0.8em;
  margin: 2px 6px 8px;
  color: #777;
}

.legend span {
  position: relative;
  font-size: 0.8em;
}

.legend img {
  width: 1em;
  height: 1.1em;
  float: left;
  margin: 0 4px 0 0;
  opacity: 0.8;
}

.marker-mycluster {
  background-color: #8094B1;
}
.marker-mycluster div {
  background-color: #1F8299;
  color:white;
}


#btTop {
  box-shadow: none;
  display: none;
  margin-top: 20px;
}

.typography p{
 text-align: justify;
}
.typography table {
  border-spacing: 0px; 
  border-collapse: collapse!important;
}
.typography table caption{
  background-color: #6F6F6E;
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 10px 10px 10px;
  font-weight: 600;
  font-size:1rem;
  line-height: 1.3rem;
}
.typography table thead th{
  background-color: #DADAD9;
  font-weight: 600;
  text-align: left;
  padding: 10px 10px 10px 10px;

}
.typography table tbody td,
.typography table tbody th{
  text-align: left;
  padding: 5px 10px 5px 10px;
  border: 1px solid #DADAD9;

}
.typography li{
 padding-left: 20px;
 padding-bottom: 5px;
}
.typography p.lead{
 font-weight: 500;
}
.typography h2{
  font-size:1.4rem;
  line-height: 1.7rem;
  font-weight: 500;
}
.typography p + h2{
  margin-top: 50px;
}
.typography h3{
  font-size:1.2rem;
  line-height: 1.33rem;
  font-weight: 600;
  margin-bottom: 15px;
}
.typography p + h3{
  margin-top: 40px;
}
.typography h4{
  font-size:1.067rem;
  line-height: 1.33rem;
  font-weight: 500;
  margin-bottom: 15px;
}
.typography p + h4,
.typography p + h5{
  margin-top: 30px;
}
.typography h5{
  font-size: 1rem;
  line-height: 1.067rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.typography blockquote{
  margin-top: 30px;
  margin-bottom:30px;
  font-style: italic;
  font-size: 120%;
  color: #6F6F6E;
}
.typography p{
  line-height: 1.3rem;
}
.typography blockquote footer{
  margin-top: 10px;
}
footer{
  box-shadow: none!important;
}