
.home{
  padding-top: 0px;
}
.home h2.card-title{
  background-color: #8094B1;
}
.home .card .content{
  font-size: 1rem;
  line-height: 1.5rem;
}
.home .card .content img{
  display: none;
}

.home #visualisation svg{
  border: none;
  margin-top:15px;
  align-items: center;
  width: 100%;
  height: 100%;
}


.home #random_wills {
  margin-bottom: 35px;
}
.home #random_wills .card{
  text-align: center;
}
.home #random_wills img{
  border: 1px solid #2E5D81;
  width:auto;
  height: 120px;
  margin-bottom: 10px;
  -moz-box-shadow: 4px 4px 7px 0px #cfcfcf;
  -webkit-box-shadow: 4px 4px 7px 0px #cfcfcf;
  -o-box-shadow: 4px 4px 7px 0px #cfcfcf;
  box-shadow: 4px 4px 7px 0px #cfcfcf;
  filter:progid:DXImageTransform.Microsoft.Shadow(color=#cfcfcf, Direction=134, Strength=7);
}
.home #random_wills .random_wills .card{
  margin-bottom: 20px;
}
.home #random_wills .random_wills{
  margin:20px -20px 0px -20px;
}
.home #random_wills .testatorName{
  color:#1F8299;
  font-weight: 600;
  font-size:0.985rem;
}
.home #random_wills img:hover{
   border-color: #8DB2C2;
}
.home #random_wills .testatorName:hover{
  color:#8DB2C2;
}

.home .searchForm{
  background-color: #DADAD9;
}
.home .searchForm label{
  font-weight: 700;
  font-size:0.89rem;
  display: block;
}
.home .searchForm input{
  color: black!important;
}
.home .searchForm .moreCriteria{
 padding: 4px 7px 4px 7px;
 border-radius: 5px;
 font-size: 0.9rem;
 margin-top: 20px;
}
.home .searchForm .fontWeightLight{
  font-size:0.975rem;
}
.home .searchForm svg.cancel-icon{
  fill: #1f8399;
}

.home .searchForm .dateFilter{
  margin-top: 24px;
}

.home .searchForm button div{
  font-weight: normal;
  font-size: 1.05rem;
  color: #8DB2C2;
}




.home .css-1py3auy{
  font-family: 'Fira Sans', sans-serif!important;
  font-size:1rem;
}
.home .e1hwwpu60{
  border:none;
}
.home .searchResults .resultLine{
  padding-top: 5px;
  padding-bottom: 5px;
}
.home .searchResults .resultLine:nth-child(odd){
 background-color: #DADAD9;
}
.home .searchResults .resultLine a{
 font-weight: 600;
 font-family: 'Fira Sans', sans-serif!important;
 color:#1F8299;
 font-size:1rem;
}
.home .searchResults .resultLine:hover{
 background-color: #C6C6C5;
}




.home .euiButtonEmpty__text {
  display: none;
}

.home .map-container {
  width: 100%;
  height: 30em;
}

