.compare #btnBack{
	margin-top: 20px;
	margin-bottom: 30px;
}
.compare .tabs{
	margin:auto;
	display: inline-block;
}
.compare .tabs {
	color:#B91918;
	padding-bottom: 10px;
	padding-top: 5px;
}
.compare .tabs button{
	color:#1F8299;
    font-size: 0.9rem;	
    padding-right: 6px;
    padding-left: 6px;
	font-family: 'Fira Sans';
}
.compare .tabs button.active{
	color:#B91918;
	font-weight: 600;
}
.compare .tabs i{
	padding-right: 2px;
}
.compare .will_pages button.active,
.compare .will_pages button:focus{
	background-color: #B91918!important;
}