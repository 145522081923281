.notices {
  margin-bottom: 50px;
}

.notices .headingBar{
  padding: 5px 20px 5px 20px;
}

.notices .headingBar .select{
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.notices .headingBar .select button{
  width: 200px;
}

.notices .headingBar .select > div {
    color: black;
}
.notices .headingBar .select button{
  height: 32px!important;
  min-height: 32px!important;
}

.notices.units .headingBar svg {
    width: 0.675em;
    height: 0.675em;
    margin-right: 3px;
}

.notices .countResults,
.notices .sort_results{
 padding-top: 20px;
 font-family: 'PT Sans';
}
.notices .countResults{
  font-size: 0.9rem;
  font-weight: 700;
  color: black;
  padding-left: 15px;
}

.notices .sort_results .select .MuiInputBase-input{
  padding-top: 0.2rem;
}
.notices .sort_results label{
    font-size: 0.9rem;
    font-family: 'PT Sans';
    padding-right: 5px;
    color: black;
    font-weight: bold;
}



.notices .leftColumn{
  padding: 15px 25px 5px 10px;
  background-color: #F5F5F5;
}
.notices .leftColumn .resultList ul{
  list-style-type: none;
  font-family: "Fira Sans";
  color:black;
  font-size: .975rem;
  padding:0;
}
.notices .leftColumn .resultList ul li{
  padding-top:6px;
  padding-bottom: 6px;
}
.notices .leftColumn .resultList ul li.active{
  color:#B91918;
  font-weight: 600;
}
