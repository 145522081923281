.loginRegister header{
	box-shadow: none;
	margin-bottom: 20px;
}

.loginRegister .tabsLoginRegister button{
	background-color: #8DB2C2;
	color: white;
}
.loginRegister .tabsLoginRegister button.Mui-selected,
.loginRegister .tabsLoginRegister button:focus{
	background-color: #8DB2C2!important;
	color: white;
}
.loginRegister button.togglePassword:hover{
	background-color: inherit!important;
	color:#1F8299;
}

.login #resetMDP {
  color: #1F8299;
}
.login #resetMDP:hover {
  color: #8DB2C2;
}

