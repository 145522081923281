
.search .leftSidebar {
 padding: 24px 24px 24px 24px;
 background-color: #DADAD9;
}

.search .leftSidebar ul.places_list {
	columns: 2;
	margin-top: 6px;
	margin-bottom: 10px;
	padding: 0;
}
.search .leftSidebar ul.places_list li{
	padding: 0;
	margin:0;
}
.search .leftSidebar ul.places_list li div{
  min-width: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: 'Fira Sans', sans-serif!important;
 }
.search .leftSidebar ul.places_list li p{
  color:black;
  padding: 0;
  margin:0;

}
.search .leftSidebar ul.places_list li svg{
 font-size: 1.1rem;
}
.search .leftSidebar ul.places_list li .MuiButtonBase-root{
	padding-right: 5px;
	padding-left: 1px;
	padding-top: 0px;
	padding-bottom: 0px;
    margin: 0;
}
.search .leftSidebar ul.places_list li p{
	font-family: 'PT Sans'!important;
	font-size: 0.85rem;
}

.search .leftSidebar .select div{
	color:black!important;
}

.search .leftSidebar .selected div{
	color: #B91918!important;
}

.search .leftSidebar  .dateFilter{
	margin-top: 30px;
	margin-bottom: 30px;
	font-family: "PT Sans", sans-serif;
}

.search .leftSidebar .dateFilter .e1hwwpu60{
	border:none;
}
.search .leftSidebar  label{
	font-weight: 700;
	color:black;
  font-size:0.9rem;
  display: block;
}
.search .leftSidebar .fontWeightLight{
  font-size:0.975rem;
}

.search .leftSidebar 
.search .leftSidebar svg.cancel-icon{
	fill:#1f8399;
}
.search .map-container {
	margin-top: 30px;
	z-index: 1;
}
.search .pagination{
	padding-bottom: 30px;
}
